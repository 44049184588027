import React, { useEffect } from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"

export default function TeamBoysKindergarten() {
    
  return ( 
    <Layout>
    <section className="container">
    <h1>Leeds City Juniors Kindergarten</h1>
    <div className="row mb-2">
        <div className="col-md-8">
            <div className="row">
                <img src="/instinct-kindergarten.jpg" className="img-fluid mb-4" />
            </div>
        </div>

        <div className="col-md-4">
            <div className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
            <div className="col p-4 d-flex flex-column position-static">
                <h3 className="lcj-color">Team Information</h3>
                <dl>
                <dt>Age Range</dt>
                <dd>Pre School to School Year 2</dd>
                <dt>Manager(s)</dt>
                <dd>Nathan Hunt</dd>
                <dt>Training</dt>
                <dd>Saturday 10:30am on Bedquilts</dd>
                <dt>Join the team</dt>
                <dd><a href="mailto:instinctsportscoaching@gmail.com">instinctsportscoaching@gmail.com</a></dd>
                </dl>
            </div>
            </div>

            <div className="row">
            <div className="col">
                <div className="card shadow-sm">
                <div className="card-header">Team Sponsor</div>
                <img src="/sponsors-wanted.jpg" />
                <div className="card-body">
                    <p className="card-text">Interested in sponsoring a team? Click <Link to="/sponsors" title="Sponsors">here</Link> to find out more.</p>
                </div>
                </div>
            </div>    
            </div>
        </div>
    </div>
    </section>
    </Layout>
  );
}

